/* ========================================================================
     Component: typo
 ========================================================================== */


/* Roboto @font-face */

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: $body-color;
    font-size: 0.875rem; // 14px
}

h1,
h2,
h3,
h4 {
    font-weight: 500;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.65625rem;
    small {
        font-size: 65%;
    }
}

h1,
.h1 {
    font-size: 2.25rem; // 36px
}

h2,
.h2 {
    font-size: 1.875rem; // 30px
}

h3,
.h3 {
    font-size: 1.5rem; // 24px
}

h4,
.h4 {
    font-size: 1.125rem; // 18px
}

h5,
.h5 {
    font-size: .875rem;
}

h6,
.h6 {
    font-size: 1rem;
}

p {
    margin-bottom: .65rem; // ~10px
}

.article {
    font-size: 20px;
    line-height: 32px;
}

strong {
    font-weight: 700;
}

small {
    font-weight: 300;
}
