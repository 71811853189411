/* You can add global styles to this file, and also import other style files */

//== Vendor
@import "~simple-line-icons/css/simple-line-icons.css";
//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
@import "~ngx-toastr/toastr.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
//==Placeholder loading
@import "~placeholder-loading/src/scss/placeholder-loading";
@import "https://cdn.materialdesignicons.com/5.6.55/css/materialdesignicons.min.css";
@import "./styles_common.scss";
@import "./styles_loader.scss";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@media print {
  // app-root > * { display: none; }
  // app-root app-print-layout { display: block; }
  //app-profile-table-widget { display: block;}
}
.bs-datepicker-container {
  padding: 0;
}

.contact-profile-dialog {
  width: 40vw;
}

.layout-boxed .feed-column-list app-feed-widget {
  width: 48% !important;
}

// .modal-resource .modal-dialog {
//   max-width: 800px;
// }
@media (max-width: 1300px) {
  .contact-profile-dialog {
    width: 80vw;
  }
}

@media screen and (max-width: 600px) {
  .layout-boxed .feed-column-list {
    app-feed-widget {
      width: 100% !important;
    }
  }
  .modal-resource .modal-dialog {
    max-width: 600px;
  }
}

@media screen and (max-width: 425px) {
  .btn-primary-lausd {
    font-size: 0.75rem;
  }
  .btn-outline-primary-lausd {
    font-size: 0.75rem;
  }
}

.swal-text {
  text-align: center;
}

.swal-title{
  // font-size: 10px !important;  
  // white-space: pre-wrap !important;  
  // padding-left: 100px !important;
  // text-align: left;
}

.text-overflow-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

.card-full-height {
  .card,
  .md-card {
    height: 100%;
  }
}

.card {
  .card-img {
    border-radius: 0;
    &.card-horizontal {
      border-right-width: 4px;
      border-right-style: solid;
      border-right-color: $primary;
    }
  }
}

.flex-container {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}

.text-primary-lausd {
  color: #143f69;
}

.text-secondary-lausd {
  color: #0079c4;
}

.text-warning-lausd {
  color: #f9a13a;
}
.text-gray {
  color: #777b83;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local("Material Icons"), local("MaterialIcons-Regular");
}

.notif-expansion {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
.reminder-active {
  display: block;
  position: absolute;
  bottom: -15px;
  right: -10px;
  z-index: 10000;
  color: #f9a13a;
  font-size: 20px;
}
.action-items {
  .text-primary {
    cursor: pointer;
  }
}
.avatar-reminder {
  width: 56px;
  height: 56px;
}

.offwhite-background {
  mat-dialog-container {
    background: #f5f7fa;
    padding: 15px 15px 2px 15px;
  }
}

.btn-primary-lausd {
  color: #fff;
  background-color: #143f69;
  border-color: #143f69;
  &:hover {
    color: #fff !important;
  }
}

.btn-outline-primary-lausd {
  color: #143f69;
  background-color: transparent;
  border: 2px solid #143f69;
  &:hover {
    color: #143f69;
  }
}

.contact {
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 20px !important;
    margin-left: 10px !important;
  }
}

.mat-card {
  background-color: #0079C4;
  color: #fff;
}

// .chat-box {

//   .cdk-virtual-scroll-content-wrapper {
//     display: flex;
//     flex-direction: column-reverse;
//   }
// }
